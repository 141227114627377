
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import JnProgress from "@/views/common/Progress.vue";
import { Picklist } from "@/models/picklist.model";
import { User } from "@/models/crm/user.model";
import { Purchase } from "@/models/crm/purchase.model";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Mail } from "@/models/mail.model";
import { EmailTemplates } from "@/models/enums.model";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { Service } from "@/models/crm/service.model";
import MetaInfo from "vue-meta";
import { saveAs } from "file-saver";

@Component({
  components: {
    JnProgress,
    ValidationObserver,
    ValidationProvider,
    StripeCheckout,
  },
  metaInfo(this: JnAbbonamentiAdmin): MetaInfo {
    return { title: "JuraNews - Abbonamenti" };
  },
})
export default class JnAbbonamentiAdmin extends Vue {
  @Prop({ required: false })
  result?: string;

  firstName = "";
  lastName = "";
  email = "";
  password = "";
  passwordProgress = 0;
  passwordConfirm = "";
  passwordConfirmProgress = 0;
  profession = new Picklist({ value: "", label: "" });
  professionPicklist: Picklist[] = [];
  firstContact = new Picklist({ value: "", label: "" });
  firstContactPicklist: Picklist[] = [];
  codiceFiscale = "";
  partitaIva = "";
  codiceDestinatario = "";
  pec = "";
  address = "";
  cap = "";
  citta = "";
  provincia = new Picklist({ value: "", label: "" });
  provincePicklist: Picklist[] = [];
  mobileNumber = "";
  regExPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  fileModulo = null;
  fileDistinta = null;

  showPassword = false;
  showPasswordConfirm = false;
  alert = false;
  alertMessage = "";
  alertTimeout = 6000;
  loading = false;
  success = false;

  serviceTrial = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  service1Mese = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  service1Anno = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  service2Anni = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );

  service = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  serviceItems: any[] = [];

  submitted = false;

  get progressPassword(): number {
    this.passwordProgress = this.progress(this.password, this.passwordProgress);
    return this.passwordProgress;
  }

  get colorPassword() {
    if (this.passwordProgress < 80) {
      return "error";
    } else if (this.passwordProgress >= 80 && this.passwordProgress < 100) {
      return "warning";
    } else {
      return "success";
    }
  }

  get progressPasswordConfirm(): number {
    this.passwordConfirmProgress = this.progress(
      this.passwordConfirm,
      this.passwordConfirmProgress
    );
    return this.passwordConfirmProgress;
  }

  progress(password: string, passwordProgress: number) {
    passwordProgress = Math.min(80, password.length * 10);
    if (this.regExPassword.test(password)) {
      passwordProgress = passwordProgress + 20;
    }
    return passwordProgress;
  }

  subscribe(service: Service) {
    this.service = service;
    if (service.serviceId == process.env.VUE_APP_VTIGER_TRIAL_SERVICE_ID!) {
      this.$router.push("/richiesta-trial");
    } else {
      const serviceItem = {
        price: String(service.stripePriceID),
        quantity: 1,
      };
      this.serviceItems.push(serviceItem);
    }
  }

  get colorPasswordConfirm() {
    if (!this.passwordMatching) {
      return "error";
    } else {
      return "success";
    }
  }

  /* assignModulo(target: any) {
    this.fileModulo = target;
  }

  assignDistinta(target: any) {
    this.fileDistinta = target;
  }*/

  async submit() {
    this.loading = true;
    const user = new User(
      this.email.toLowerCase(),
      this.email.toLowerCase(),
      this.email.toLowerCase(),
      this.firstName,
      this.lastName,
      this.profession.value,
      this.firstContact.value,
      this.mobileNumber,
      this.citta,
      "IT",
      this.address,
      this.cap,
      this.provincia.value,
      this.password,
      this.codiceFiscale,
      "",
      "",
      "",
      false
    );

    await JnService.sendRequestAbbonamento(
      new Mail({
        to: process.env.VUE_APP_JURANEWS_MAIL!,
        template: EmailTemplates.RICHIESTA_ABBONAMENTO,
        locals: {
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.email,
          mobileNumber: user.mobileNumber,
          profession: user.profession,
          firstContact: user.firstContact,
          service: this.service.name,
        },
        attachments: [],
        /* attachments: [
          {
            path:
              process.env.VUE_APP_MODULES_BASE_PATH +
              "/juranews-api/uploads/" +
              user.email +
              "-Modulo.pdf",
          },
          {
            path:
              process.env.VUE_APP_MODULES_BASE_PATH +
              "/juranews-api/uploads/" +
              user.email +
              "-Distinta.pdf",
          },
        ],*/
      }),
      this.fileModulo,
      this.fileDistinta
    );
    this.loading = false;
    this.submitted = true;
  }

  cancel() {
    this.serviceItems = [];
    this.service = new Service(
      "",
      "",
      "",
      0,
      "",
      "",
      Number(process.env.VUE_APP_PRODUCT_ID)
    );
  }

  get passwordMatching(): boolean {
    return (
      this.password == this.passwordConfirm &&
      this.regExPassword.test(this.password)
    );
  }

  get passwordFormatOk() {
    return this.regExPassword.test(this.password) && this.password.length >= 8;
  }

  get passwordOk(): boolean {
    return this.passwordMatching && this.passwordFormatOk;
  }

  async created() {
    this.loading = true;
    this.professionPicklist = await JnService.getPicklist("Contacts", "title");
    this.firstContactPicklist = await JnService.getPicklist(
      "Contacts",
      "leadsource"
    );
    this.provincePicklist = await JnService.getProvince();
    this.serviceTrial = await JnService.getService(
      process.env.VUE_APP_VTIGER_TRIAL_SERVICE_ID!
    );
    this.service1Mese = await JnService.getService(
      process.env.VUE_APP_VTIGER_1MESE_SERVICE_ID!
    );
    this.service1Anno = await JnService.getService(
      process.env.VUE_APP_VTIGER_1ANNO_SERVICE_ID!
    );
    this.service2Anni = await JnService.getService(
      process.env.VUE_APP_VTIGER_2ANNI_SERVICE_ID!
    );
    this.loading = false;
  }
}
